import { SecondaryButton } from '@nib-components/button';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import { Box, Stack, breakpoint } from '@nib/layout';
import React from 'react';
import styled from 'styled-components';
import arrow from './arrow.svg';
import Link from '@nib-components/link';

export interface DriverCardProps {
  title?: string;
  subtitle?: string;
  description?: string;
  image?: string;
  buttonText?: string;
  backgroundColor?: string;
  href?: string;
  alt?: string;
}

const Button = styled(SecondaryButton)`
  opacity: 1;
  transition: opacity 300ms ease-in-out, scale 300ms ease-in-out, margin-left 300ms ease-in-out;

  ${breakpoint('xl')`
    margin-left: 8px;
    scale: 0.95;
    opacity: 0;
  `}
`;

const DriverCardContainer = styled.div`
  cursor: pointer;
  overflow: hidden;
  width: 100%;

  &:hover {
    ${Button} {
      margin-left: 0;
      opacity: 1;
      scale: 1;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 185px;
  object-fit: cover;
  transition: transform 300ms ease-in-out;

  ${breakpoint('xl')`
    min-height: 160px;
    max-height: 160px;
  `}

  ${DriverCardContainer}:hover & {
    transform: scale(1.02);
  }
`;

const Arrow = styled.img`
  width: 68px;
  height: 68px;
  position: absolute;
  right: 0;
  padding: 8px;
  transition: padding 300ms ease-in-out;

  ${DriverCardContainer}:hover & {
    padding: 4px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;

const DriverCard = (props: DriverCardProps) => {
  const { title, subtitle, description, image, alt, buttonText, backgroundColor, href } = props;

  return (
    <DriverCardContainer>
      <Link href={href} underline={false}>
        <Box display="flex" flexDirection="column" height="100%">
          {image && (
            <Box display="flex" position="relative">
              <ImageContainer>
                <Image src={image} alt={alt} data-testid="driver-card-image" />
                <Arrow src={arrow} data-testid="driver-card-arrow" alt="" />
              </ImageContainer>
            </Box>
          )}
          <Box padding={4} background={backgroundColor} height="100%">
            <Box
              display="flex"
              flexDirection="column"
              gap={4}
              justifyContent="space-between"
              height="100%"
            >
              <Stack space={2}>
                {subtitle && (
                  <Copy color="trueGreen" transform="uppercase">
                    {subtitle}
                  </Copy>
                )}
                {title && (
                  <Heading color="trueGreen" size={3} component="h3">
                    {title}
                  </Heading>
                )}
                <Copy size="medium">{description}</Copy>
              </Stack>
              {buttonText && (
                <Box>
                  <Button href={href}>{buttonText}</Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Link>
    </DriverCardContainer>
  );
};

export default DriverCard;
