import React from 'react';
import Helmet from 'react-helmet';

import Copy, { Bold } from '@nib-components/copy';
import Heading from '@nib-components/heading';
import { Box, Section, Stack, Tiles } from '@nib/layout';

import Layout from '../components/Layout';
import { ContentfulDocument } from '../constructs/models';
import metrics from '../metrics';
import ContentHeroPanel, { ContentHeroPanelProps } from '../components/ContentHeroPanel';
import DriverCard, { DriverCardProps } from '../components/DriverCard';

import MotherHoldingBabyOnBeachImage from '../img/life-and-living/mother-holding-baby-on-beach.jpeg';
import CoupleLookingAtComputerImage from '../img/life-and-living/couple-looking-at-computer.jpeg';
import FamilyDancingImage from '../img/life-and-living/family-dancing.jpeg';
import CoupleHuggingImage from '../img/life-and-living/couple-hugging.jpeg';
import FamilyWalkingIntoHomeImage from '../img/life-and-living/about.jpeg';

import landingPage from '../img/life-and-living/NIB0201_Team_Web_Banner_800x290px_v1_HR.jpg';
import landingPageDesktop from '../img/life-and-living/NIB0201_Team_Web_Banner_1600x580px_v1_HR.jpg';
import ContentBanner, { ContentBannerProps } from '../components/ContentBanner';
import ImportantInformationBanner, {
  ImportantInformationBannerProps,
} from '../components/ImportantInformationBanner';
import Link from '@nib-components/link';
import { ExternalLinkSystemIcon } from '@nib/icons';
import { urlConstants } from '../constructs/constants';
import { colorTrueGreen } from '@nib-components/theme';
import useSalesforceForm from '../hooks/useSalesforceForm';

const title = 'Life Insurance & Living Insurance';
const description =
  'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.';

type DocumentResult = {
  edges: {
    node: {
      document: ContentfulDocument;
    };
  }[];
};

interface ImageShape {
  file: {
    url: string;
  };
}

interface HtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
  };
}
interface RawAndHtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
    rawMarkdownBody: string;
  };
}

interface HeroImagesShape {
  desktop: ImageShape;
  desktop2x4000x1200: ImageShape;
  mobile2x800x300: ImageShape;
  mobile400x150: ImageShape;
  tablet2x1600x580: ImageShape;
  tablet800x290: ImageShape;
}

interface NodeShape {
  title: string;
  startDate: string;
  heroTitle: string;
  heroCopy: RawAndHtmlMarkdownShape;
  leftButtonText: string;
  rightButtonText: string;
  legal: RawAndHtmlMarkdownShape;
  campaignCode: string;
  heroImage: HeroImagesShape;
  importantThingsToKnow: HtmlMarkdownShape;
}

type LifeCoverOptionsPageResult = {
  edges: {
    node: NodeShape;
  }[];
};

type GraphQLResult = {
  temporaryAccidentalDeathCover: DocumentResult;
  lifePolicyDocument: DocumentResult;
  factSheetUrl: DocumentResult;
  lifeCoverOptionsPage: LifeCoverOptionsPageResult;
};

interface LifeCoverOptionsProps {
  data: GraphQLResult;
}

const driverCardAboutData: DriverCardProps = {
  title: 'I want to know the difference between Life and Living insurance',
  subtitle: 'About',
  description:
    "Life insurance and Living insurance should be easy to understand, even if it isn't always easy to discuss. Learn the differences.",
  image: FamilyDancingImage,
  alt: 'A joyful family dancing together in their cozy living room, filled with laughter and warmth.',
  buttonText: 'See your options',
  backgroundColor: 'sunsetPink',
  href: urlConstants.aboutLifeLivingInsurance,
};

const driverCardCurrentOfferData: DriverCardProps = {
  title: 'I want to protect my home and future',
  subtitle: 'Homeowners',
  description: 'Help safeguard your financial stability when life takes an unexpected turn.',
  image: FamilyWalkingIntoHomeImage,
  alt: 'Family with two children carrying boxes enters a sunlit living room through sliding glass doors.',
  buttonText: 'Learn more',
  backgroundColor: 'brightGreen40',
  href: urlConstants.lifeLivingHomeowners,
};

const driverCardPricingData: DriverCardProps = {
  title: 'I want to know how much Life insurance and Living insurance may cost me',
  subtitle: 'Pricing',
  description:
    'See which products might best meet your needs and get an estimate with our Estimator Tool.',
  image: CoupleHuggingImage,
  alt: "A man and woman smile joyfully while seated together at a table, enjoying each other's company.",
  buttonText: 'Get an estimate',
  backgroundColor: 'warmWhite80',
  href: urlConstants.estimateYourCoverageLifeLivingInsurance,
};

const contentBannerAboutData: ContentBannerProps = {
  title: 'What will happen to my family if I die or am unable to work due to illness?',
  subtitle: 'Why get Life & Living cover',
  description: (
    <Copy measure={false}>
      Nobody knows what’s coming around the corner in life. That’s why we offer life & living
      insurance, so you can prepare for the unexpected and help ensure your loved ones are taken
      care of — even if you’re no longer around.
    </Copy>
  ),
  image: MotherHoldingBabyOnBeachImage,
  imageAlt:
    "A woman embraces a child on the sandy beach, surrounded by the ocean's waves and a bright, sunny sky.",
  primaryButtonText: 'How to protect what matters',
  primaryButtonHref: urlConstants.aboutLifeLivingInsurance,
  backgroundColor: 'sageGreen60',
};

const contentBannerContactData: ContentBannerProps = {
  title: 'Ask a human. A real one.',
  subtitle: 'Contact us',
  description: (
    <Copy measure={false}>
      If you need help deciding what Life insurance and Living insurance is right for you, email or
      speak with one of our friendly life insurance and living insurance specialists.
    </Copy>
  ),
  image: CoupleLookingAtComputerImage,
  imageAlt:
    'A man and woman collaborate while examining a laptop screen together, sharing ideas and insights.',
  primaryButtonText: 'Contact us',
  primaryButtonHref: urlConstants.lifeLivingInsuranceContactUs,
  flip: true,
  showAnimatedArrow: true,
};

const importantInformationData: ImportantInformationBannerProps = {
  title: 'Important Information',
  description: [
    <Heading component="h4" color={colorTrueGreen}>
      *Get $300 Off Any Life & Living Insurance Policy - Terms and Conditions
    </Heading>,
    <Copy measure={false} size="small">
      You (the policy owner) are eligible for this offer (Offer) if you submit an application for a
      new Life & Living product (Eligible Policy) between 1 March and 30 June 2025 (or such later
      date as nib may permit in its absolute discretion) and your application for cover is accepted
      by nib.
      <br />
      <br />
      The Offer of $300 off will be applied as a credit on the Eligible Policy in the second month
      after your Eligible Policy is issued and after you’ve made the minimum premium payments:
      <br />
      <br />
      If you pay weekly, the first four weekly premium payments need to be paid
      <br />
      <br />
      If you pay fortnightly, the first two fortnightly premium payments need to be paid
      <br />
      <br />
      If you pay by another frequency, the first premium payment needs to be paid
      <br />
      <br />
      The credit is not transferable, refundable or redeemable for cash.
      <br />
      <br />
      Maximum credit value of $300 per Eligible Policy, limited to one Eligible Policy for any one
      person insured during this promotional period, between 1 March and 30 June 2025.
      <br />
      <br />
      The credit will automatically go towards future premiums on the Eligible Policy and when the
      credit runs out, premiums will be payable at the applicable rate and the Eligible Policy will
      continue to be charged on the selected payment frequency. The credit may result in a partial
      payment being due, before payments return to the regular premium amount.
      <br />
      <br />
      The Offer cannot be used in conjunction with any other Life & Living Insurance offers.
      <br />
      <br />
      These terms and conditions may be updated or the Offer cancelled by nib provided this does not
      prejudice anyone who has already received the Offer. They were last updated on 20 February
      2025. nib will publish any change on our website{' '}
      <Link href="/" icon={ExternalLinkSystemIcon} target="_blank">
        https://nib.co.nz
      </Link>
      .
      <br />
      <br />
      The Offer is promoted by nib nz insurance limited, 0800 555 642,{' '}
      <Link href="mailto:lifesales@nib.co.nz" icon={ExternalLinkSystemIcon}>
        lifesales@nib.co.nz
      </Link>
      . Level 9, 48 Shortland Street, Auckland City, 1010.
    </Copy>,
    <Heading component="h3" color={colorTrueGreen}>
      Additional Information
    </Heading>,
    <Copy measure={false} size="small">
      Only the main features and benefits of the Life & Living Insurance is outlined here. For a
      full explanation of all benefits, exclusions and other terms and conditions of the cover, view
      the{' '}
      <Link href={urlConstants.lifeAndLivingInsuranceWording} icon={ExternalLinkSystemIcon}>
        Life & Living Insurance wording
      </Link>
      .
    </Copy>,
    <Copy measure={false} size="small">
      Life & Living Insurance is provided by nib nz insurance limited and is a related company of
      nib nz limited (“nib”). nib nz insurance limited issues and underwrites Life & Living
      Insurance products, and is solely responsible for claims under the cover. nib sells and may
      advise you on these products. Find out more about{' '}
      <Link href={urlConstants.financialStrength}>
        financial strength and financial advice with nib
      </Link>
      .
    </Copy>,
  ],
};

const LifeAndLivingLanding = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  useSalesforceForm();
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/life-cover-options',
    url: 'https://www.nib.co.nz/life-cover-options',
    description:
      'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.',
    name: ['Life Insurance & Living Insurance | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  const contentHeroData: ContentHeroPanelProps = {
    title: (
      <>
        Protect those who matter most with{' '}
        <span style={{ color: '#82E578' }}>$300 off Life & Living Insurance</span> after paying for
        your first month.*
      </>
    ),
    subtextLeft: 'LIFE INSURANCE',
    subtextRight: 'LIVING INSURANCE',
    description: (
      <>
        A great game plan protects what matters most. And life is no different. For a limited time,
        get <b>$300 off nib Life & Living insurance</b> after you pay for your first month or more.
        T&Cs apply.
      </>
    ),
    imageMobile: landingPage,
    imageDesktop: landingPageDesktop,
    imageAlt:
      'A man and a boy sit on a concrete bench in a park, holding a frisbee. The background is a grassy area with trees.',
    primaryButton: {
      text: 'Estimate your cover',
      url: urlConstants.estimateYourCoverageLifeLivingInsurance,
    },
    secondaryButton: {
      text: 'Request a call',
      url: urlConstants.lifeLivingInsuranceContactUs,
    },
  };

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <ContentHeroPanel {...contentHeroData} />
      <LetTalkAboutSection />
      <ContentBanner {...contentBannerAboutData} />
      <ContentBanner {...contentBannerContactData} />
      <ImportantInformationBanner {...importantInformationData} />
    </Layout>
  );
};

const LetTalkAboutSection = (): JSX.Element => {
  return (
    <Section role="section">
      <Stack space={{ xs: 5 }}>
        <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center">
          <Bold color={colorTrueGreen} transform="uppercase" align="center">
            Let's Talk About You
          </Bold>
          <Heading fontType="serif" color="trueGreen" size={{ xs: 2, xl: 2 }} component="h2">
            I'm here because...
          </Heading>
        </Box>
        <Tiles flex={true} space={{ xs: 4, xl: 0 }} columns={{ xs: 1, xl: 3 }}>
          <DriverCard {...driverCardAboutData} />
          <DriverCard {...driverCardCurrentOfferData} />
          <DriverCard {...driverCardPricingData} />
        </Tiles>
      </Stack>
    </Section>
  );
};

export default metrics({ pageName: 'life-and-living-insurance' })(LifeAndLivingLanding);
